import { useMemo } from 'react'

import { Stack } from '@mui/material'
import { Close } from '@mui/icons-material'

import { ColorModeTypeEnum } from '@enums'

import Typography from '../Typography'

import { IDialogHeaderProps } from './DialogHeader.types'
import { StyledIconButton } from './DialogHeader.styled'

const DialogHeader = (props: IDialogHeaderProps) => {
  const {
    dialogTitle,
    mode = ColorModeTypeEnum.LIGHT,
    onClose,
    children,
    ...otherProps
  } = props

  const color = useMemo(() => {
    if (mode === ColorModeTypeEnum.LIGHT) {
      return 'var(--Neutral10)'
    }

    return 'var(--Neutral1)'
  }, [mode])

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      {...otherProps}
    >
      <Stack direction="row" gap="4px" alignItems="center">
        <Typography
          textAlign="left"
          fontVariant="heading-4"
          weight="semibold"
          color={color}
        >
          {dialogTitle}
        </Typography>

        {children}
      </Stack>

      <StyledIconButton aria-label="close" onClick={onClose} mode={mode}>
        <Close />
      </StyledIconButton>
    </Stack>
  )
}

export default DialogHeader
