import React from 'react'

import getYouTubeID from 'get-youtube-id'

import { v4 as uuid } from 'uuid'

import { MobXProviderContext } from 'mobx-react'

import {
  type CompetitionBaseDto,
  type CompetitionDetailsDTO
} from '@elitecompetitions/client-api'

import { KlaviyoEventEnum } from '@enums'

import { isBrowser } from '@helpers/isBrowser'

import * as Sentry from '@sentry/nextjs'

import { authStoreFactory } from '@store/Auth'
import { ComposedStore } from '@store'

export const getYouTubeThumbnail = (url: string) => {
  let id = getYouTubeID(url)

  if (!id && url.length === 11) {
    id = url
  }

  return {
    default: {
      url: 'https://img.youtube.com/vi/' + id + '/default.jpg',
      width: 120,
      height: 90
    },
    medium: {
      url: 'https://img.youtube.com/vi/' + id + '/mqdefault.jpg',
      width: 320,
      height: 180
    },
    high: {
      url: 'https://img.youtube.com/vi/' + id + '/hqdefault.jpg',
      width: 480,
      height: 360
    }
  }
}

export const klaviyo_added_to_cart_bulk = (
  ticketsCount: number,
  competition: CompetitionBaseDto | CompetitionDetailsDTO
) => {
  try {
    if (typeof window !== 'undefined' && window.klaviyo) {
      const platform = getAppPlatform()
      const { profile } = authStoreFactory()
      if (!profile) {
        return
      }
      const payload = {
        ticketsCount: ticketsCount,
        competitionName: competition.name,
        competitionImage: competition.image,
        competitionKey: competition.id,
        competitionUrl:
          'https://' +
          window.location.hostname +
          '/competitions/' +
          competition.slugUrl,
        quantity: ticketsCount,
        $value:
          (competition.isOnSale
            ? competition.saleTicketPrice
            : competition.ticketPrice) * ticketsCount,
        $event_id: uuid(),
        platform,
        categories: []
      }

      if (competition.categories) {
        payload.categories = competition.categories.map(
          category => category.name
        )
      }

      window.klaviyo.identify<
        {
          $email: string
        },
        () => void
      >(
        {
          $email: profile.email
        },
        () => {
          window.klaviyo.track<KlaviyoEventEnum, typeof payload, () => void>(
            KlaviyoEventEnum.ADDED_TO_CART,
            payload,
            () => console.log('track event sent')
          )
        }
      )
    }
  } catch (err) {
    Sentry.captureException(err)
  }
}

export const convertToMinFromSec = seconds => {
  const date = new Date(null)
  date.setSeconds(seconds)
  const result = date.toISOString().substr(14, 5)

  return result
}

export const useStores = () => {
  return React.useContext(MobXProviderContext) as ComposedStore
}

export const safeParsePhoneNumber = (phoneNumber = ''.toString()) => {
  if (!phoneNumber || phoneNumber === '') {
    return phoneNumber
  }

  if (phoneNumber.startsWith('0')) {
    return phoneNumber.replace(/0/, '+44')
  } else if (!phoneNumber.startsWith('44') && !phoneNumber.startsWith('+44')) {
    if (phoneNumber.startsWith('+')) {
      return phoneNumber
    }

    return '+44' + phoneNumber
  }

  return phoneNumber.replace(/^(\+?440)|^(\+?44)/, '+44')
}

export const getTimeSince = date => {
  // @ts-ignore
  let seconds = Math.floor((new Date() - new Date(date)) / 1000)

  let interval = seconds / 86400

  if (interval > 1) {
    return new Date(date).toLocaleDateString()
  }

  interval = seconds / 3600

  if (interval > 1) {
    const time = Math.floor(interval)

    return time + (time === 1 ? ' hour' : ' hours')
  }

  interval = seconds / 60

  if (interval > 1) {
    const time = Math.floor(interval)

    return time + (time === 1 ? ' min' : ' mins')
  }

  if (seconds <= 0) {
    seconds = 1
  }

  return Math.floor(seconds) + ' secs'
}

// region Flutter WebView

export const getAppPlatform = () => {
  let result = 'WEB'

  if (isBrowser()) {
    result = (localStorage.getItem('nativeApp') || 'WEB').toUpperCase() as
      | 'WEB'
      | 'ANDROID'
      | 'IOS'
  }

  return result
}

export const isIOS = () => {
  const platform = getAppPlatform()

  return platform === 'IOS'
}

export const isANDROID = () => {
  const platform = getAppPlatform()

  return platform === 'ANDROID'
}

export const isWEB = () => {
  const platform = getAppPlatform()

  return platform === 'WEB'
}

export const isMobileApp = () => {
  const platform = getAppPlatform()

  return platform === 'IOS' || platform === 'ANDROID'
}

// endregion

export const isIos = () => {
  if (!isBrowser()) {
    return false
  }

  // @ts-ignore
  const platform = navigator?.userAgentData?.platform

  return (
    platform === 'iOS' ||
    (navigator?.platform === 'MacIntel' && navigator?.maxTouchPoints > 1) ||
    /iPad|iPhone|iPod/.test(navigator?.platform)
  )
}

export const isAndroid = () => {
  if (!isBrowser()) {
    return false
  }

  // @ts-ignore
  const platform = navigator?.userAgentData?.platform

  return (
    platform === 'Android' ||
    navigator?.platform === 'Android' ||
    navigator?.userAgent?.includes(' Android ') === true ||
    process?.platform === 'android'
  )
}

export function getCookies(): { [key: string]: string } {
  try {
    if (typeof window !== 'undefined') {
      return document.cookie
        .split(';')
        .map(v => v.split('='))
        .reduce((acc, v) => {
          acc[decodeURIComponent((v[0] || '').trim())] = decodeURIComponent(
            (v[1] || '').trim()
          )
          return acc
        }, {})
    }
    return {}
  } catch (err) {
    console.error('Error -> getCookies', err)
    return {}
  }
}
